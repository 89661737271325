import React from "react"

const DeveloperDiaryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="24"
      height="24"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,24.000000) scale(0.004800,-0.004800)"
        fill="#000000"
        stroke="none"
      >
        <path d="M2345 4769 c-770 -47 -1486 -527 -1824 -1223 -105 -217 -162 -393 -203 -636 -29 -168 -31 -492 -4 -655 40 -253 101 -447 206 -660 256 -520 707 -921 1249 -1109 250 -87 444 -119 721 -120 216 -1 304 8 498 51 454 99 894 366 1195 723 269 320 435 687 503 1115 25 152 25 467 1 625 -74 483 -289 911 -627 1250 -453 453 -1061 680 -1715 639z m-662 -1679 l567 -285 0 -97 0 -97 -562 -283 c-310 -156 -568 -284 -575 -286 -10 -3 -13 20 -13 91 l0 94 491 239 490 238 -73 37 c-40 20 -261 128 -490 239 l-418 203 0 99 c0 55 4 98 8 96 5 -1 263 -131 575 -288z m2177 -1310 l0 -80 -570 0 -570 0 0 80 0 80 570 0 570 0 0 -80z" />
      </g>
    </svg>
  )
}

export default DeveloperDiaryIcon
