/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import {
  FaHeart
} from "react-icons/fa"
import "bootstrap/dist/css/bootstrap.css"
import Header from "./header/header"
import "./layout.css"
import GatasbyIcon from "./footer/icons/GatasbyIcon"
import RaspberryPiIcon from "./footer/icons/RaspberryPiIcon"
import DeveloperDiaryIcon from "./footer/icons/DeveloperDiaryIcon"
import { Link } from "gatsby"

const Layout = ({ children }) => {

  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            tagline
            author
            contacts {
              linkedin
              github
              stackoverflow
              freecodecamp
              twitter
            }
          }
        }
      }
    `}
      render={data => (
        <>
          <Header
            siteTitle={data.site.siteMetadata.title}
            tagline={data.site.siteMetadata.tagline}
            author={data.site.siteMetadata.author}
            contacts={data.site.siteMetadata.contacts} />
          <div>
            <main className="p-4">{children}</main>
            <footer className="text-center">
              <hr/>
              <p className="d-inline">
              <DeveloperDiaryIcon size={15}/> © {new Date().getFullYear()} Developer Diary.</p>
              <p className="mt-5 text-muted d-inline"> Made with
                <FaHeart size={15} style={{ color: "red", margin: "0px 5px" }} />
                using <GatasbyIcon/>, served to your browser from a home grown <RaspberryPiIcon/> cluster. <br/>
                <a href="mailto:contact-me@developerdiary.me">
                    <large className="d-inline-block ml-3">contact-me@developerdiary.me</large>
                </a>
              </p>
            </footer>
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
