import React from "react"

import "./header.css"
import amilas from "../../images/amilas.jpg"

const MobileBio = (props) => {


    return (
        <div className="mobile-bio-main">
            <img src={amilas}  className="ml-4 mt-2" style={{ maxWidth: `75px`, maxHeight: `75px`, borderRadius: `50%`,boxShadow: `1px 1px 3px`}} alt="author-pic" />
            <div className="mr-4 mt-4">
                <h4 >{props.author}</h4>
                <small style={{ float: "right" }}>{props.tagline}</small>
            </div>
        </div>
    )
}

export default MobileBio